/** @jsx jsx */
import React, { useState, useEffect, useContext } from 'react'
import { NavLinks } from '@/data'
import { Col, Container, Row } from 'react-bootstrap'
import { SearchContext } from '@/context/search-context'
import { MenuContext } from '@/context/menu-context'
import { Link } from 'gatsby'
import { jsx } from '@emotion/react'
import LogoFractal from '@/images/img/Logotipo-fractal.svg'
import IconInstagram from '@/images/img/ico-instagram.svg'
import IconLinkedin from '@/images/img/ico-linkedin.svg'

import {
  header,
  logo,
  mainMenu,
  submenu,
  navigator,
  navLinksStyle,
} from '../assets/styles/Header.styles'

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false)
  const { searchStatus, updateSearchStatus } = useContext(SearchContext)
  const { menuStatus, updateMenuStatus } = useContext(MenuContext)
  const handleSearchClick = (e) => {
    e.preventDefault()
    updateSearchStatus(!searchStatus)
  }
  const handleMenuClick = (e) => {
    e.preventDefault()
    updateMenuStatus(!menuStatus)
  }

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true)
    } else if (window.scrollY < 70) {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [sticky])

  return (
    <header
      css={header}
      className={`${true === sticky ? 'fixedHeader animated flipInX' : null}`}
      id='header'
    >
      <Container className='customContainer'>
        <Row className='customRow'>
          <Col className='col-6 col-logo' lg={2} md={2} sm={3}>
            <div css={logo}>
              <Link to='/'>
                <img
                  src={LogoFractal}
                  alt='Fractal - RETHINK, REINVENT, REBIZ'
                  title='Fractal - RETHINK, REINVENT, REBIZ'
                />
              </Link>
            </div>
          </Col>
          <Col lg={8} sm={8} md={8} className='col-nav'>
            <nav css={mainMenu} className='text-center'>
              <ul className='main-ul'>
                {NavLinks.map((links, index) => {
                  return (
                    <>
                    <li
                      css={navLinksStyle}
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? 'menu-item-has-children'
                          : ''
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} className='sub-menu'>
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      <img style={{marginLeft: '5px', paddingTop: '4px'}} src={links.icon ? links.icon : ''} alt="" />
                    </li>
                    </>
                  )
                })}
              </ul>
            </nav>
          </Col>
          <Col lg={2} md={4} sm={2} className='col-6 col-btn'>
            <div css={navigator} className='text-right'>
              <div className='social-media-icons'>
                <a href='https://www.instagram.com/wearefractal/' title="Instagram Fractal" target="_blank"><img src={IconInstagram} /></a>
                <a href='https://www.linkedin.com/company/fractal-thinktech' title="Linkedin Fractal" target="_blank"><img src={IconLinkedin} /></a>
              </div>
              <a
                href='#'
                className='menu mobilemenu d-none d-md-none d-lg-none'
              >
                <i className='mei-menu'></i>
              </a>
              <a
                id='open-overlay-nav'
                className='menu hamburger'
                onClick={handleMenuClick}
                href='#'
              >
                <i className='mei-menu'></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default HeaderOne
